import React from 'react';
import './about.css';

const About = () => (
  <main id="about">
    <h2>About this site</h2>
    <p>
      Demo for micro frontend
    </p>
  </main>
);

export default About;
